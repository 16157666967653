import React, {useState} from "react";
import {FC, ReactElement} from "react";
// @ts-ignore
import * as style from './LoginForm.module.scss';
import {Button, Form} from "react-bootstrap";
import {Client, handleApiError} from "../../services/ApiService";
import {navigate} from "gatsby";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/pro-solid-svg-icons";
import {useDispatch} from "react-redux";
import {setMember, setMemberID, setVendorID} from "../../stores/member/memberSlice";
import {setBasketAmount, setBasketID} from "../../stores/basket/basketSlice";
import {setKey} from "../../stores/auth/authSlice";

interface LoginFormProps {
    onSuccess?: (member: any) => void;
}

interface FormInputs {
    Username: string,
    Password: string,
}

const schema = yup.object().shape({
    Username: yup.string().required('Bitte gib einen Benutzernamen an.'),
    Password: yup.string().required('Bitte gib ein Passwort ein.')
});

export const LoginForm: FC<LoginFormProps> = ({onSuccess}): ReactElement => {
    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });
    const dispatch = useDispatch();

    function onSubmit(userData: FormInputs) {
        setLoading(true);
        Client.Raw.post('Member/authorize', {
            Email: userData.Username,
            Password: userData.Password
        }).then((resAuth) => {
            Client.Raw
                .get('Member/' + resAuth.data.MemberID)
                .then((res) => {
                    dispatch(setVendorID(res.data.VendorID));
                    dispatch(setMember(res.data));
                    toast.success('Erfolgreich eingeloggt!');
                    onSuccess && onSuccess(res.data);
                }).catch(handleApiError)

            dispatch(setKey(resAuth.data.Key));
            dispatch(setMemberID(resAuth.data.MemberID));
        }).catch((error) => {
            handleApiError(error)
            setLoading(false);
        });
    }

    return (
        <div className={style.loginForm}>
            <div className="mb-3">
                <span className={style.formTitle}>Login</span>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="loginEmail" className="mb-2">
                    <Form.Label className={style.formLabel}>Benutzer</Form.Label>
                    <Form.Control className={style.formControl} type="text" {...register('Username')} />
                    <Form.Text className="errorMsg">
                        {errors.Username?.message}
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="loginPassword">
                    <Form.Label className={style.formLabel}>Passwort</Form.Label>
                    <Form.Control className={style.formControl} type="password" {...register('Password')} />
                    <Form.Text className="errorMsg">
                        {errors.Password?.message}
                    </Form.Text>
                </Form.Group>
                <div className="d-flex">
                    <Button type="submit" className={style.formSubmit} disabled={loading}>
                        <FontAwesomeIcon icon={faUserCircle} className={style.icon}/>
                        Login
                    </Button>
                </div>
            </Form>
        </div>
    );
}