import * as React from "react";
import {FC, useEffect, useRef, useState} from "react";
import {Client, handleApiError} from "../../services/ApiService";
import {connect, ConnectedProps} from "react-redux";
// @ts-ignore
import * as style from './CheckoutBasket.module.scss';
import {Button, Col, Form, Modal} from "react-bootstrap";
import BasketLoader from "../ContentLoaders/BasketLoader/BasketLoader";
import {setBasketAmount, setBasketID} from "../../stores/basket/basketSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/pro-light-svg-icons";
import {toast} from "react-toastify";
import * as yup from "yup";
import {array} from "yup";
import {wrappingResolver} from "gatsby/dist/schema/resolvers";
import GreetingPreview from "./GreetingPreview";
import {faEye, faPen, faPlus, faMinus} from "@fortawesome/pro-solid-svg-icons";
// @ts-ignore
import './modal.scss';

const mapState = ({member, basket, auth}) => ({
    member,
    basket,
    auth,
})

const mapDispatch = {
    setBasketAmount,
    setBasketID
}
const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
    setBasketAmount(state: number): void
    setBasketEmpty(state: boolean): void
    setGreetingName(state: object[]): void
    setGreetingHeading(state: object[]): void
    setGreetingText(state: object[]): void,
    setBasketValue(state: string): void,
    vendors: any,
}

const CheckoutBasket: FC<Props> = ({
                                       member, children, basket, auth, setBasketAmount, setBasketEmpty, setGreetingName,
                                       setGreetingHeading,
                                       setGreetingText, vendors, setBasketValue
                                   }) => {
    const [basketItems, setBasketItems] = useState([]);
    const [basketProducts, setBasketProducts] = useState([]);
    const [totalCost, setTotalCost] = useState('');
    const [loading, setLoading] = useState(true);
    const [versandkosten, setVersandkosten] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [greetingNames, setGreetingNames] = useState([]);
    const [greetingHeadings, setGreetingHeadings] = useState([]);
    const [greetingTexts, setGreetingTexts] = useState([]);
    const [showGreeting, setShowGreeting] = useState({
        OrderItem: 0,
        Bool: false
    });
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [currentName, setCurrentName] = useState('');
    const [currentHeader, setCurrentHeader] = useState('');
    const [currentText, setCurrentText] = useState('');
    const [currentLogo, setCurrentLogo] = useState('');
    const [currentProduct, setCurrentProduct] = useState('');
    const [vendorShippingPrices, setVendorShippingPrices] = useState([]);
    const [hasReachedMinAmount, setHasReachedMinAmount] = useState(false);
    const [gtagSent, setGtagSent] = useState(false);

    useEffect(() => {
        setGreetingName(greetingNames)
        setGreetingHeading(greetingHeadings)
        setGreetingText(greetingTexts)

    }, [greetingNames, greetingHeadings, greetingTexts])

    useEffect(() => {
        console.log(versandkosten);
    }, [versandkosten])


    function CustomModal(props) {
        return (
            <Modal {...props} size={'md'} aria-labelledby={props.basketItem.ID} centered>
                <Modal.Header closeButton>
                    <Modal.Title id={props.basketItem.ID}>
                        {currentProduct}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GreetingPreview
                        header={currentHeader ? escapeHtml(currentHeader) : 'Alles Gute zum Geburtstag!'}
                        intro={currentName ? escapeHtml(currentName) : 'Liebe Anna,'}
                        text={currentText ? escapeHtml(currentText) : 'Ich wünsche dir viel Spaß mit ' + props.basketItem.Product.Title + ' von fairStärkt!'}
                        vendorLogo={currentLogo ?? null}/>
                </Modal.Body>

            </Modal>
        );
    }

    useEffect(() => {
        console.log("LOOK FOR THIS:")
        console.log(basketProducts)
        if (basketProducts.length > 0 && totalCost && !gtagSent) {
                // @ts-ignore
                let gme_orderBtnClicked = {
                    'event': 'begin_checkout',
                    'data': {
                        'currency': 'EUR',
                        'value': totalCost,
                        'coupon': 0,
                        'items': basketProducts
                    }
                }

                // @ts-ignore
                window.dataLayer.push(gme_orderBtnClicked);
                console.log(window.dataLayer)
                console.log("gtag done")
                setGtagSent(true);
        }
    }, [basketProducts, totalCost])


    useEffect(() => {
        // const parsedTotalCost = parseFloat(totalCost.replace("€", "").replace(",", ".").trim());
        // console.log(parsedTotalCost);

        // if (parsedTotalCost >= 120.00) {
        //     console.log("free");
        //     setVersandkosten("€ 0,00");
        // }

        setBasketValue(totalCost);
    }, [totalCost]);



    useEffect(() => {
        console.log("doing smth i guess")
        if (auth.Key) {
            Client.Basket.fetchAuthorized(auth.Key, basket.BasketID).then((res) => {
                if (res.data.List.length) {
                    console.log("this Versand")
                    console.log(res.data.ShippingNice)
                    let dummy = [];
                    setTotalCost(res.data.TotalNice);
                    setVersandkosten(res.data.ShippingNice);
                    setVendorShippingPrices(res.data.ShippingVendors)
                    res.data.List.forEach(list => {
                        dummy.push(...list.Items);
                    })
                    setBasketItems(dummy);
                    setBasketEmpty(false)
                } else {
                    setBasketEmpty(true)
                }
                setLoading(false)
            }).catch(handleApiError);

            Client.Basket.fetchAuthorizedForGtag(auth.Key, basket.BasketID).then((res) => {
                if (res.data.List.length) {
                    let dummy2 = [];
                    res.data.List.forEach(list => {
                        let p = list.Items[0];
                        dummy2.push(p);
                    })
                    setBasketProducts(dummy2);
                } else {
                }
                setLoading(false)
            }).catch(handleApiError);
        } else {
            Client.Basket.fetch(basket.BasketID ? basket.BasketID : '').then((res) => {
                setTotalCost(res.data.TotalNice);
                setVersandkosten(res.data.ShippingNice);
                setBasketAmount(res.data.TotalItems);
                setBasketID(res.data.ID);
                setVendorShippingPrices(res.data.ShippingVendors)
                setTaxAmount(res.data.TaxRateFormatted);
                setTimeout(() => {
                    setLoading(false)
                }, 300)
                if (res.data.List.length) {
                    let dummy = [];
                    res.data.List.forEach(list => {
                        dummy.push(...list.Items);
                    })
                    setBasketItems(dummy);
                }
            })

            Client.Basket.fetchForGtag(basket.BasketID ? basket.BasketID : '').then((res) => {
                if (res.data.List.length) {
                    let dummy2 = [];
                    res.data.List.forEach(list => {
                        let p = list.Items[0];
                        dummy2.push(p);
                    })
                    setBasketProducts(dummy2);
                }
            })
        }
    }, [vendors, totalCost])

    function deleteItem(itemID: number) {
        setLoading(true);
        Client.Basket.deleteItem(basket.BasketID, itemID).then(() => {
            Client.Basket.fetchAuthorized(auth.Key, basket.BasketID).then((res) => {
                if (res.data.List.length) {
                    let dummy = [];
                    res.data.List.forEach(list => {
                        dummy.push(...list.Items);
                    })
                    setBasketItems(dummy);
                } else {
                    setBasketItems([])
                }
                toast.success('Produkt erfolgreich entfernt.');
                setBasketAmount(res.data.TotalItems)
                setTotalCost(res.data.TotalNice);
            }).catch(handleApiError)

            Client.Basket.fetchAuthorizedForGtag(auth.Key, basket.BasketID).then((res) => {
                console.log(res.data)
                if (res.data.List.length) {
                    let dummy2 = [];
                    res.data.List.forEach(list => {
                        let p = list.Items[0];
                        dummy2.push(p);
                    })
                    setBasketProducts(dummy2);
                } else {
                    setBasketProducts([])
                }
            }).catch(handleApiError)
            setLoading(false)
        }).catch(handleApiError);
    }

    function greetingCardEditCheck(basketItem) {
        return !greetingTexts.filter((item) => item.OrderItem === basketItem.ID).slice(-1)[0]?.GreetingsText && !greetingHeadings.filter((item) => item.OrderItem === basketItem.ID).slice(-1)[0]?.GreetingsHeading && !greetingNames.filter((item) => item.OrderItem === basketItem.ID).slice(-1)[0]?.GreetingsName;
    }

    function fetchVendors() {
        let vendorList = '?filter[ID:not]=-1';

        basketItems.map((item) => {
            if (item.Product.VendorID !== null) {
                vendorList += '&filter[ID][]=' + item.Product.VendorID;
            }
        })

        if (basketItems.length > 0) {
            Client.Raw.get('Vendor/getActiveVendorsMinimalized' + vendorList).then((res) => {
                setVendors(res.data);
                setTimeout(() => {
                    setLoading(false)
                }, 300)
            }).catch(handleApiError);
        }
    }

    function newQuantity(event, id, prodID) {
        setLoading(true)
        Client.Basket.modifyItem(basket.BasketID, id, prodID, event.target.value).then((res) => {
            Client.Basket.fetchAuthorized(auth.Key, basket.BasketID).then((res) => {
                let dummy = [];
                res.data.List.forEach(list => {
                    dummy.push(...list.Items);
                })

                setBasketItems(dummy);
                setTotalCost(res.data.SubTotalNice);
                setBasketAmount(res.data.TotalItems)
                setLoading(false)
                Client.Raw.get('Basket/' + basket.BasketID + '/' + 'getHasMinOrderAmount').then((res) => {
                    setHasReachedMinAmount(res.data.HasReached);
                }).catch(handleApiError)
            }).catch(handleApiError);

            Client.Basket.fetchAuthorizedForGtag(auth.Key, basket.BasketID).then((res) => {
                let dummy2 = [];
                res.data.List.forEach(list => {
                    let p = list.Items[0];
                    dummy2.push(p);
                })
                setBasketProducts(dummy2);
            }).catch(handleApiError);
        }).catch(handleApiError).then(fetchVendors)
    }

    /**
     * Only available for 2020+ browser releases
     * @function
     * @param {string} unsafe
     */
    const escapeHtml = (unsafe) => {
        return unsafe.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;').replaceAll("'", '&#039;');
    }


    return (
        <div className={style.basketHolder}>
            {loading ? <BasketLoader amount={5}/> :
                <>
                    <h2 className={style.subHeading}>Bestellübersicht</h2>
                    <div className={style.holder}>
                        {basketItems.length ?
                            <div className={style.basketItems}>
                                <>
                                    {basketItems.map((basketItem, index) => {
                                        return (
                                            <div className={style.basketItemWrapper}>
                                                <div className={style.basketItem} key={index}>
                                                    <Col xs={4}>
                                                        <img src={basketItem.Product.PreviewImage}
                                                             className={style.basketImage}/>
                                                    </Col>
                                                    <Col xs={7} className={style.itemNameHolder}>
                                                        {basketItem.Product.Title} <br/>
                                                        <Form.Group className={style.selectionHolder}>
                                                            <Form.Control as={"select"}
                                                                          defaultValue={basketItem.Quantity}
                                                                          className={style.selection}
                                                                          onChange={(event) => newQuantity(event, basketItem.ID, basketItem.ProductID)}>
                                                                {Array.from(Array((basketItem.Product.OnStock + 1) > 20 ? 21 : (basketItem.Product.OnStock + 1)).keys()).map((option, index) => {
                                                                    return (
                                                                        <>
                                                                            {option !== 0 &&
                                                                                <option value={option}
                                                                                        key={index}> {option}</option>}
                                                                        </>
                                                                    )
                                                                })}
                                                            </Form.Control> x {basketItem.Product.NicePrice} <br/>
                                                        </Form.Group>
                                                        inkl {basketItem.Product.TaxRateFormatted} MwSt.
                                                    </Col>
                                                    <Col xs={1} onClick={() => deleteItem(basketItem.ProductID)}>
                                                        <FontAwesomeIcon icon={faTimes} className={style.removeItem}/>
                                                    </Col>
                                                </div>
                                                <Col xs={12}>
                                                    {!showGreeting.Bool ? (
                                                        <Button className={style.submitButton + ' position-relative'}
                                                                onClick={(e) => {
                                                                    setShowGreeting({
                                                                        OrderItem: basketItem.ID,
                                                                        Bool: true
                                                                    });
                                                                    setGreetingNames([]);
                                                                    setGreetingHeadings([]);
                                                                    setGreetingTexts([]);
                                                                    setCurrentName('');
                                                                    setCurrentText('');
                                                                    setCurrentHeader('');
                                                                }}>

                                                            <FontAwesomeIcon
                                                                icon={greetingCardEditCheck(basketItem) ? faPlus : faPen}
                                                                className={style.icon}/>
                                                            Grußkarte {greetingCardEditCheck(basketItem) ? 'hinzufügen' : 'bearbeiten'}
                                                            {greetingCardEditCheck(basketItem) ? (
                                                                <span
                                                                    className="badge position-absolute top-0 start-100 translate-middle bg-dark">Neu</span>
                                                            ) : null}
                                                        </Button>
                                                    ) : null}

                                                    {showGreeting.Bool ?
                                                        <Form.Group
                                                            className={!showGreeting || showGreeting.OrderItem !== basketItem.ID ? 'd-none' : 'mb-4 mt-4'}>
                                                            <Form.Label>Name des Empfängers</Form.Label>
                                                            <Form.Control as={"input"}
                                                                          placeholder={"Liebe Anna..."}
                                                                          className={'mb-2 ' + style.basketInputField}
                                                                          onChange={(e) => {
                                                                              setGreetingNames([...greetingNames, {
                                                                                  OrderItem: basketItem.ID,
                                                                                  GreetingsName: e.target.value
                                                                              }]);
                                                                              setCurrentName(e.target.value)
                                                                              setCurrentText(greetingTexts.filter((item) => item.OrderItem === basketItem.ID).slice(-1)[0]?.GreetingsText);
                                                                              setCurrentHeader(greetingHeadings.filter((item) => item.OrderItem === basketItem.ID).slice(-1)[0]?.GreetingsHeading);
                                                                              setCurrentProduct(basketItem.Product.Title)
                                                                              setCurrentLogo(vendors.filter((vendor) => vendor.ID === basketItem.Product.VendorID)[0]?.LogoURL)
                                                                          }}
                                                            >
                                                            </Form.Control>
                                                            <Form.Label>Einleitung</Form.Label>
                                                            <Form.Control as={"input"}
                                                                          className={'mb-2 ' + style.basketInputField}
                                                                          placeholder={"Alles Gute zum Geburtstag..."}
                                                                          onChange={(e) => {
                                                                              setGreetingHeadings([...greetingHeadings, {
                                                                                  OrderItem: basketItem.ID,
                                                                                  GreetingsHeading: e.target.value
                                                                              }]);
                                                                              setCurrentHeader(e.target.value);
                                                                              setCurrentText(greetingTexts.filter((item) => item.OrderItem === basketItem.ID).slice(-1)[0]?.GreetingsText);
                                                                              setCurrentName(greetingNames.filter((item) => item.OrderItem === basketItem.ID).slice(-1)[0]?.GreetingsName);
                                                                              setCurrentProduct(basketItem.Product.Title)
                                                                              setCurrentLogo(vendors.filter((vendor) => vendor.ID === basketItem.Product.VendorID)[0]?.LogoURL)
                                                                          }}
                                                            >
                                                            </Form.Control>
                                                            <Form.Label>Grußnachricht</Form.Label>
                                                            <Form.Control as={"textarea"}
                                                                          className={style.basketInputField}
                                                                          placeholder={"Ich wünsche dir sehr viel Spaß mit " + basketItem.Product.Title + "..."}
                                                                          onChange={(e) => {
                                                                              setGreetingTexts([...greetingTexts, {
                                                                                  OrderItem: basketItem.ID,
                                                                                  GreetingsText: e.target.value
                                                                              }]);
                                                                              setCurrentText(e.target.value);
                                                                              setCurrentHeader(greetingHeadings.filter((item) => item.OrderItem === basketItem.ID).slice(-1)[0]?.GreetingsHeading);
                                                                              setCurrentName(greetingNames.filter((item) => item.OrderItem === basketItem.ID).slice(-1)[0]?.GreetingsName);
                                                                              setCurrentProduct(basketItem.Product.Title)
                                                                              setCurrentLogo(vendors.filter((vendor) => vendor.ID === basketItem.Product.VendorID)[0]?.LogoURL)

                                                                          }}


                                                            >
                                                            </Form.Control>
                                                            <CustomModal show={show} basketItem={basketItem}
                                                                         onHide={() => setShow(false)}
                                                            />
                                                        </Form.Group>
                                                        : null}

                                                    {showGreeting.Bool ?
                                                        <Button className={style.submitButton + ' position-relative'}
                                                                onClick={(e) => {
                                                                    setShowGreeting({
                                                                        OrderItem: basketItem.ID,
                                                                        Bool: false
                                                                    });
                                                                    setGreetingNames([]);
                                                                    setGreetingHeadings([]);
                                                                    setGreetingTexts([]);
                                                                    setCurrentName('');
                                                                    setCurrentText('');
                                                                    setCurrentHeader('');
                                                                }}>
                                                            <FontAwesomeIcon
                                                                icon={faMinus}
                                                                className={style.icon}/>
                                                            Grußkarte entfernen

                                                        </Button> : null}
                                                </Col>
                                            </div>
                                        )
                                    })}
                                </>
                            </div>
                            : <p>Keine Produkte im Warenkorb</p>
                        }

                        {showGreeting.Bool && greetingTexts.length !== 0 ?
                            <span className={style.previewToggle + ' ' + style.previewButton} ref={target}
                                  onClick={() => setShow(!show)}>
                                                            <FontAwesomeIcon icon={faEye} className={style.icon}/>
                                                            Vorschau anzeigen
                        </span> : null}

                        <div className={style.basketPrice}>
                            <Col lg={6} className={"d-flex justify-content-start align-items-center"}>
                                Versandkosten:
                            </Col>
                            <Col lg={6}>
                                <small> {versandkosten}</small>
                            </Col>
                            {/* @ts-ignore */
                                vendors.length > 1 && versandkosten !== '€ 0,00' ? vendors.map((item) => {
                                    return (
                                        <>
                                            <Col lg={6}
                                                 className={"d-flex text-left justify-content-start align-items-center"}>
                                                <small> für <a className={style.noStyleLink}
                                                               href={'haendleruebersicht/' + item.URLSegment}>{item.Title}</a></small>
                                            </Col>

                                            <Col lg={6}>
                                                <small>{vendorShippingPrices[item.ID]}</small>
                                            </Col></>
                                    )
                                }) : null}
                            <Col lg={12} className={style.basketBorder}/>
                            <Col lg={6} className={"d-flex justify-content-start align-items-center"}>
                                <h5>Gesamtsumme:</h5>
                            </Col>
                            <Col lg={6}>
                                <h5> {totalCost ? totalCost : '€0'}</h5>
                            </Col>
                        </div>
                    </div>
                </>
            }

            {children}
        </div>
    )
}

export default connector(CheckoutBasket);
