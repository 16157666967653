import * as React from "react";
import {useState} from "react";
// @ts-ignore
import * as style from './RegisterBeforeCheckout.module.scss';
import BasicRegistration from "../Registration/BasicRegistration/BasicRegistration";
import Loading from "../Loading/Loading";

type Props = {
    completed(state: boolean): void
}

const RegisterBeforeCheckout = ({completed}) => {
    const [loading, setLoading] = useState(false);
    return (
        <div className={style.form}>
            {loading ?
                <Loading type={"border"} message={"Daten werden verarbeitet."}/> :
                <>
                    <div className="mb-3">
                        <span className={style.info}>Um eine Bestellung zu tätigen, bitten wir dich, einen Account anzulegen. Vielen Dank! :)</span>
                    </div>
                    <BasicRegistration loadingCallback={(state) => {
                        setLoading(state);
                        setTimeout(() => {
                            completed(state);
                        }, 500);
                    }} basic={false} checkout={true}/>
                </>
            }
        </div>
    )
}

export default RegisterBeforeCheckout;
