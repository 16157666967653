// extracted by mini-css-extract-plugin
export var basketBorder = "CheckoutBasket-module--basketBorder--vqNfh";
export var basketHolder = "CheckoutBasket-module--basketHolder--4s26c";
export var basketImage = "CheckoutBasket-module--basketImage--wWNxw";
export var basketInputField = "CheckoutBasket-module--basketInputField--g9WhK";
export var basketItem = "CheckoutBasket-module--basketItem--vvKc+";
export var basketItemWrapper = "CheckoutBasket-module--basketItemWrapper--YEfQf";
export var basketItems = "CheckoutBasket-module--basketItems--bRuUH";
export var basketPrice = "CheckoutBasket-module--basketPrice--rCP-w";
export var icon = "CheckoutBasket-module--icon--pbEt2";
export var itemNameHolder = "CheckoutBasket-module--itemNameHolder--MqymD";
export var noStyleLink = "CheckoutBasket-module--noStyleLink--jZreR";
export var preview = "CheckoutBasket-module--preview--qYs69";
export var previewBackdrop = "CheckoutBasket-module--previewBackdrop--S7Nw7";
export var previewButton = "CheckoutBasket-module--previewButton--6JuxN";
export var previewOverlayWrapper = "CheckoutBasket-module--previewOverlayWrapper--eSxpu";
export var previewToggle = "CheckoutBasket-module--previewToggle--3sJzm";
export var previewWrapper = "CheckoutBasket-module--previewWrapper--f9+gE";
export var removeItem = "CheckoutBasket-module--removeItem--Qohmx";
export var selection = "CheckoutBasket-module--selection--29eei";
export var selectionHolder = "CheckoutBasket-module--selectionHolder--dA0Ko";
export var subHeading = "CheckoutBasket-module--subHeading--0O+Qd";
export var submitButton = "CheckoutBasket-module--submitButton--MhphO";