import * as React from "react";
// @ts-ignore
import fontStyle from './fonts/stylesheet.css';
import {useEffect} from "react";



const GreetingPreview = ({header, intro, text, vendorLogo}) => {

    const html = `
<style>

\t.info-bottom {
\t\tmargin-left: 30px;
\t}

\t.a4 {
\t\tbackground: white;
\t\tposition: relative;
\t\tdisplay: flex;
\t\tflex-direction: column;
\t\tflex-wrap: wrap;
height: 100%;
\t}

\t.half, .quarter {
\t\toverflow: hidden;
\t\twidth: 50%;
\t}

\t.half {
\t\tflex: 1 1 100%;
\t}

\t.quarter {
\t\tflex: 1 1 50%;
\t}

\t.flex {
\t\tdisplay: flex;
\t}

\t.dot {
\t\tfont-size: 20px;
\t\tfont-weight: bold;
\t\tpadding-left: 5px;
\t\tpadding-right: 5px;
\t\tposition: absolute;
\t\tline-height: 25px;
\t\ttext-align: center;
\t\tmin-width: 15px;
\t\theight: 25px;
\t\tborder: 2px #000 solid;
\t\tborder-radius: 50%;
\t}

\t.dot.filled {
\t\tbackground: #000;
\t}

\t.dot.filled.red {
\t\tbackground: #F00;
\t\tborder-color: #F00;
\t}

\t.square {
\t\tdisplay: block;
\t\theight: 15px;
\t\twidth: 15px;
\t\tbackground-color: #000;
\t\tmargin: 10px auto;
\t}

\t.left .vertical {
\t\twriting-mode: vertical-rl;
\t\ttransform: rotate(180deg);
\t\tjustify-content: space-between;
\t\tflex-direction: column;
\t\theight: calc(100% - 1cm);
\t\twidth: calc(100% - 1cm);
\t\tmargin: 0.5cm;
\t}

\t.left .vertical .stations {
\t\tdisplay: flex;
\t}

\t.left .vertical .stations .station {
\t\tflex: 0 0 25%;
\t}

\t.left .vertical .stations .station .station-number {
\t\tfont-weight: bold;
\t\tfont-size: 50px;
\t\tmargin-bottom: 40px;
\t}

\t.left .vertical .stations .station .station-items {
\t\tfont-size: 16px;
\t\tline-height: 1.2;
\t}

\t.left .info {
\t\tdisplay: flex;
\t}

\t.left .info .info-item {
\t\tposition: relative;
\t\tflex-wrap: wrap;
\t}

\t.left .info .info-item .info-item-line {
\t\tflex: 0 0 100%;
\t}

\t.left .info .info-item .info-item-line.half {
\t\tflex: 0 0 50%;
\t}

\t.left .info .info-item .info-item-line .at-bio-logo {
\t\theight: 40px;
\t\tpadding: 5px;
\t\ttransform: rotate(90deg);
\t}

\t.upper.right .inner {
\t\tmargin: 0.5cm;
\t\twidth: calc(100% - 1cm);
\t\theight: calc(100% - 1cm);
\t\tflex-direction: column;
\t\tjustify-content: space-between;
\t}

\t.upper.right .inner .service-title {
\t\tfont-size: 40px;
\t\ttext-align: right;
\t}

\t.upper.right .inner .service-title b {
\t\tfont-size: 45px;
\t\tfont-weight: bold;
\t}

\t.upper.right .inner .delivery {
\t\tjustify-content: space-between;
\t\tflex-wrap: wrap;
\t}

\t.upper.right .inner .delivery .delivery-address {
\t\tmargin-top: 80px;
\t\tfont-size: 14px;
\t\tmax-width: calc(100% - 125px);
\t}

\t.upper.right .inner .delivery .exclusive {
\t\tfont-size: 10px;
\t\tline-height: 1.1;
\t\twhite-space: nowrap;
\t\tmax-width: 125px;
\t\tmargin-top: 80px;
\t}

\t.upper.right .inner .delivery .note {
\t\tmargin-top: 0.2cm;
\t\tflex: 1 1 100%;
\t\tfont-size: 12px;
\t\tline-height: 1.1;
\t}

\t.upper.right .inner .delivery .note span {
\t\tdisplay: block;
\t}

\t.upper.right .inner .info {
\t\tmargin-top: 1cm;
\t\tmargin-bottom: 0.5cm;
\t}

\t.upper.right .inner .info .title {
\t\tfont-size: 15px;
\t\tfont-weight: bold;
\t\tmargin-bottom: 0.5cm;
\t\tdisplay: block;
\t}

\t.upper.right .inner .info .info-line .packer {
\t\tflex-grow: 1;
\t\tfont-size: 10px;
\t\tline-height: 1.1;
\t}

\t.upper.right .inner .info .info-line .bio-label .at-bio-logo {
\t\theight: 40px;
\t\tdisplay: inline-block;
\t}

\t.upper.right .inner .info .info-line .bio-label .lot {
\t\tfont-size: 10px;
\t\tline-height: 1.1;
\t\tdisplay: inline-block;
\t\tvertical-align: top;
\t}

\t.upper.right .inner .info .info-line .bio-label .at-bio-info {
\t\tfont-size: 10px;
\t\tline-height: 1.1;
\t\tfont-weight: bold;
\t}

\t.upper.right .inner .barcode {
\t\ttext-align: center;
\t\tmargin: 0 auto 50px auto;
\t}

@font-face {
    font-family: bromello;
    src: url('../../theme/fonts/bromello.woff2') format('woff2'),
        url('../../theme/fonts/bromello.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sailec';
    src: url('../../theme/fonts/Sailec-Light.woff2') format('woff2'),
        url('../../theme/fonts/Sailec-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sailec';
    src: url('../../theme/fonts/Sailec-Regular.woff2') format('woff2'),
        url('../../theme/fonts/Sailec-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sailec';
    src: url('../../theme/fonts/Sailec-Bold.woff2') format('woff2'),
        url('../../theme/fonts/Sailec-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



\t.lower.right {
\t\tbackground: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='black' stroke-width='1'/><path d='M0 0 L100 100 ' stroke='black' stroke-width='1'/></svg>") no-repeat center center;
\t\tbackground-size: 100% 100%, auto;
\t}
</style>

<div class="a4">
\t\t<meta content="width=device-width" name="viewport">
\t\t<style>
\t\t\t.bee-row,
\t\t\t.bee-row-content {
\t\t\t\tposition: relative
\t\t\t}

\t\t\t.bee-row-1,
\t\t\t.bee-row-1 .bee-row-content {
\t\t\t\tbackground-repeat: no-repeat
\t\t\t}


\t\t\t* {
\t\t\t\tbox-sizing: border-box
\t\t\t}

\t\t\tbody,
\t\t\th1,
\t\t\tp {
\t\t\t\tmargin: 0
\t\t\t}

\t\t\t.bee-row-content {
\t\t\t\tmax-width: 1440px;
\t\t\t\tmargin: 0 auto;
\t\t\t\tdisplay: flex
\t\t\t}

\t\t\t.bee-row-content .bee-col-w12 {
\t\t\t\tflex-basis: 100%
\t\t\t}

\t\t\t.bee-image {
\t\t\t\toverflow: auto
\t\t\t}

\t\t\t.bee-image .bee-center {
\t\t\t\tmargin: 0 auto
\t\t\t}

\t\t\t.bee-row-1 .bee-col-1 .bee-block-1,
\t\t\t.bee-row-1 .bee-col-1 .bee-block-7 {
\t\t\t\twidth: 100%
\t\t\t}

\t\t\t.bee-image img {
\t\t\t\tdisplay: block;
\t\t\t\twidth: 100%
\t\t\t}

\t\t\t.bee-paragraph {
\t\t\t\toverflow-wrap: anywhere
\t\t\t}

\t\t\t@media (max-width:768px) {
\t\t\t\t.bee-row-content:not(.no_stack) {
\t\t\t\t\tdisplay: block
\t\t\t\t}
\t\t\t}

\t\t\t.bee-row-1 .bee-row-content {
\t\t\t\tcolor: #000
\t\t\t}

\t\t\t.bee-row-1 .bee-col-1 {
\t\t\t\tpadding-bottom: 5px;
\t\t\t\tpadding-top: 5px
\t\t\t}

\t\t\t.bee-row-1 .bee-col-1 .bee-block-4 {
\t\t\t\twidth: 100%;
\t\t\t\ttext-align: center
\t\t\t}

\t\t\t.bee-row-1 .bee-col-1 .bee-block-2,
\t\t\t.bee-row-1 .bee-col-1 .bee-block-6 {
\t\t\t\tcolor: #393d47;
\t\t\t\tfont-size: 16px;
\t\t\t\tfont-weight: 400;
\t\t\t\tline-height: 120%;
\t\t\t\ttext-align: center;
\t\t\t\tdirection: ltr;
\t\t\t\tletter-spacing: 0
\t\t\t}

.logo-holder {
    position: relative;
}

.vendor-logo {
    position: absolute;
    top: 28%;
    right: 42.5%;
  width: 12.5%!important;
    height: auto;
}

\t\t\t.bee-row-1 .bee-col-1 .bee-block-2 p:not(:last-child),
\t\t\t.bee-row-1 .bee-col-1 .bee-block-6 p:not(:last-child) {
\t\t\t\tmargin-bottom: 16px
\t\t\t}
\t\t</style>
\t</head>
\t<body>
\t<div class="bee-page-container">
\t\t<div class="bee-row bee-row-1">
\t\t\t<div class="bee-row-content">
\t\t\t\t<div class="bee-col bee-col-1 bee-col-w12">
\t\t\t\t\t<div class="bee-block bee-block-1 bee-image logo-holder">
\t\t\t\t\t\t<img alt class="bee-center bee-fixedwidth" src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/BeeProAgency/211366_188341/editor_images/header_1.png" style="max-width:1440px;">
<img src=${vendorLogo ?? '/'} class="vendor-logo" onerror="this.style.display='none'">
\t\t\t\t\t</div>
\t\t\t\t\t<div class="bee-block bee-block-2 bee-paragraph" style="padding-right: 30px; padding-left: 30px">
\t\t\t\t\t\t<p>
${intro}
\t\t\t\t\t\t</p>
\t\t\t\t\t</div>
\t\t\t\t\t<div class="bee-block bee-block-3 bee-spacer">
\t\t\t\t\t\t<div class="spacer" style="height:25px;">
\t\t\t\t\t\t</div>
\t\t\t\t\t</div>
\t\t\t\t\t<div class="bee-block bee-block-4 bee-heading">
\t\t\t\t\t\t<h1 style="color:#393d47;font-size:30pt;font-family:bromello;line-height:120%;text-align:center;direction:ltr;font-weight:700;letter-spacing:normal;margin-top:0;margin-bottom:0;padding-right: 30px; padding-left: 30px">
        <span class="tinyMce-placeholder">
         ${header}
        </span>
\t\t\t\t\t\t</h1>
\t\t\t\t\t</div>
\t\t\t\t\t<div class="bee-block bee-block-5 bee-spacer">
\t\t\t\t\t\t<div class="spacer" style="height:30px;">
\t\t\t\t\t\t</div>
\t\t\t\t\t</div>
\t\t\t\t\t<div class="bee-block bee-block-6 bee-paragraph" style="padding-right: 30px; padding-left: 30px">
\t\t\t\t\t\t<p>
\t\t\t\t\t\t\t${text}
\t\t\t\t\t\t</p>
\t\t\t\t\t</div>
\t\t\t\t\t<div class="bee-block bee-block-7 bee-image">
\t\t\t\t\t\t<img alt class="bee-center bee-fixedwidth" src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/BeeProAgency/211366_188341/footer.png" style="max-width:1440px;">
\t\t\t\t\t</div>
\t\t\t\t</div>
\t\t\t</div>
\t\t</div>
\t</div>
</div>

`;

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </>
    )
}

export default GreetingPreview;