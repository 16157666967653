import * as React from "react";
// @ts-ignore
import * as style from './FullscreenLoading.module.scss';
import Loading from "../Loading/Loading";

const FullscreenLoading = () => {
    return (
        <div className={style.holder}>
            <Loading type={"border"} message={"Daten werden verarbeitet."} />
        </div>
    )
}

export default FullscreenLoading;
