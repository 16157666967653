import * as React from "react";
import {FC, useEffect, useState} from "react";
import Layout from "../../components/Navigation/layout";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './index.module.scss';
import CheckoutBasket from "../../components/CheckoutBasket/CheckoutBasket";
import Loading from "../../components/Loading/Loading";
import {Client, handleApiError} from "../../services/ApiService";
import {setBasketAmount, setBasketID} from "../../stores/basket/basketSlice";
import {connect, ConnectedProps} from "react-redux";
import RegisterBeforeCheckout from "../../components/RegisterBeforeCheckout/RegisterBeforeCheckout";
import FullscreenLoading from "../../components/FullscreenLoading/FullscreenLoading";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
// @ts-ignore
import * as mainStyle from "../../theme/main.module.scss";
import {Link, navigate} from "gatsby";
import {setLocale} from "yup";
import CookieGtag from "../../components/Cookies/CookieGtag";
import {toastr} from "react-redux-toastr";
import {toast} from "react-toastify";
import {LoginForm} from "../../components/LoginForm/LoginForm";
import EditNewsletterStatus from "../../components/ProfileData/EditNewsletterStatus";

const mapState = ({member, basket, auth, cookie}) => ({
    member, basket, auth, cookie
})

const mapDispatch = {
    setBasketID,
    setBasketAmount
}

const connector = connect(mapState, mapDispatch);

type Props = PropsFromRedux & {
    setBasketAmount(state: number): void
}

type PropsFromRedux = ConnectedProps<typeof connector>


interface FormInputs {
    Name: string,
    UID: string,
    Ort: string,
    Straße: string,
    Hausnummer: string,
    Land: string,
    NameShipping: string,
    UIDShipping: string,
    OrtShipping: string,
    StraßeShipping: string,
    HausnummerShipping: string,
    LandShipping: string,
    DifferentCheck: boolean,
    AcceptAGB: boolean,
    AcceptVendorAGB: boolean,
    Company: string,
    CompanyShipping: string,
    PLZ: number,
    PLZShipping: number,
}

const schema = yup.object().shape({
    Name: yup.string().required('Bitte gib deinen Vor- und Nachnamen an.'),
    UID: yup.string().optional().nullable(),
    Ort: yup.string().required('Bitte gib einen Ort an.').nullable(),
    Company: yup.string().optional().nullable(),
    CompanyShipping: yup.string().optional().nullable(),
    Straße: yup.string().required('Bitte gib eine Straße an.'),
    Hausnummer: yup.string().required('Bitte gib eine Hausnummer an.'),
    Land: yup.string().required('Bitte gib ein Land an.').nullable(),
    AcceptAGB: yup.boolean().required('Bitte akzeptiere die AGBs'),
    AcceptVendorAGB: yup.boolean().required('Bitte akzeptiere die AGBs der Händler'),
    DifferentCheck: yup.boolean().optional(),
    NameShipping: yup.string().when('DifferentCheck', {
        is: true,
        then: yup.string().required('Bitte gib deinen Vor- und Nachnamen an.'),
        otherwise: yup.string().optional()
    }),
    UIDShipping: yup.string().optional(),
    OrtShipping: yup.string().when('DifferentCheck', {
        is: true,
        then: yup.string().required("Bitte gib einen Ort an."),
        otherwise: yup.string().optional()
    }),
    StraßeShipping: yup.string().when('DifferentCheck', {
        is: true,
        then: yup.string().required("Bitte gib eine Straße an."),
        otherwise: yup.string().optional()
    }),
    HausnummerShipping: yup.string().when('DifferentCheck', {
        is: true,
        then: yup.string().required("Bitte gib eine Hausnummer an."),
        otherwise: yup.string().optional()
    }),
    LandShipping: yup.string().when('DifferentCheck', {
        is: true,
        then: yup.string().required("Bitte gib ein Land an."),
        otherwise: yup.string().optional()
    }),
})

const CheckoutPage: FC<Props> = ({member, basket, auth, cookie, setBasketAmount}) => {
    const [hasDifferentShipping, setHasDifferentShipping] = useState(false);
    const [finish, setFinish] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingCheckout, setLoadingCheckout] = useState(false);
    const [fullBasket, setFullBasket] = useState({});
    const [remark, setRemark] = useState('');
    const [basketEmpty, setBasketEmpty] = useState(false);
    const [checkoutFinished, setCheckoutFinished] = useState(false);
    const [completedRegister, setCompletedRegister] = useState(false);
    const [user, setUser] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [billingAddress, setBillingAddress] = useState(-1);
    const [shippingAddress, setShippingAddress] = useState(-1);
    const [anotherBillingAddress, setAnotherBillingAddress] = useState([]);
    const [anotherShippingAddress, setAnotherShippingAddress] = useState([]);
    const [checkedAGB, setCheckedAGB] = useState(false);
    const [vendors, setVendors] = useState([]);
    const [vendorCheckedAGB, setVendorCheckedAGB] = useState(false);
    const [greetingNames, setGreetingNames] = useState([]);
    const [greetingHeadings, setGreetingHeadings] = useState([]);
    const [greetingTexts, setGreetingTexts] = useState([]);
    const [vendorLoading, setVendorLoading] = useState(true);
    const [plzError, setPLZError] = useState('');
    const [plz, setPLZ] = useState('');
    const [plzShipping, setPLZShipping] = useState('');
    const [plzErrorShipping, setPLZErrorShipping] = useState('');
    const [authMode, setAuthMode] = useState<'login' | 'register'>('register');
    const [basketValue, setBasketValue] = useState('');

    const [newsletterStatus, setNewsletterStatus] = useState<boolean>(user.NewsletterStatus);
    const [newsletterText, setNewsletterText] = useState('');

    useEffect(() => {
        Client.Raw.get('SiteConfig').then((res) => {
            setNewsletterText(res.data[0].NewsletterText);
        }).catch(handleApiError);
    })

    function handleNewsletterChange(b: boolean) {
        setNewsletterStatus(b);
        toast.success(b ? 'Zum Newsletter angemeldet!' : null);
    }


    const {register, handleSubmit, watch, formState: {errors}, setValue} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    })

    function setBillingAddressToUse(id) {
        if (id != -1) {
            Client.Raw
                .put(`Member/${member.MemberID}`, {LastBillingAddressID: id})
                .then((res) => {
                    setUser(res.data)
                }).catch(handleApiError);
        } else {
            setValue('Name', '');
            setValue('Ort', '')
            setValue('Straße', '')
            setValue('Hausnummer', '')
            setValue('Company', '')
        }
    }

    function setShippingAddressToUse(id) {
        if (id != -1) {
            Client.Raw
                .put(`Member/${member.MemberID}`, {LastShippingAddressID: id})
                .then((res) => {
                    setUser(res.data);
                }).catch(handleApiError);
        } else {
            setValue('OrtShipping', '')
            setValue('StraßeShipping', '')
            setValue('HausnummerShipping', '')
        }
    }

    useEffect(() => {
        setValue('DifferentCheck', hasDifferentShipping)
    }, [hasDifferentShipping])

    useEffect(() => {
        if (member.MemberID) {
            Client.Member.current(auth.Key).then((res) => {
                // @ts-ignore
                setUser(res.data);
            }).catch(handleApiError);
            Client.Raw.get('Member/' + member.MemberID).then((res) => {
                if (res.data.LastShippingAddressID) {
                    setShippingAddress(res.data.LastShippingAddressID);
                    setHasDifferentShipping(true);
                    setAnotherShippingAddress(res.data.LastShippingAddress);
                }

                if (res.data.LastBillingAddressID) {
                    setBillingAddress(res.data.LastBillingAddressID);
                    setAnotherBillingAddress(res.data.LastBillingAddress);
                }

                if (res.data.Addresses.length) {
                    setAddresses(res.data.Addresses);
                } else {
                    setAddresses([]);
                }

            }).catch(handleApiError);
        }
        setTimeout(() => {
            setLoading(false)
        }, 750)
    }, [])

    useEffect(() => {
        if (member.MemberID) {
            Client.Member.current(auth.Key).then((res) => {
                // @ts-ignore
                setUser(res.data);
            }).catch(handleApiError);
        }
    }, [member])

    useEffect(() => {
        // @ts-ignore
        if (user.VendorID) {
            // @ts-ignore
            Client.Raw.get('Vendor/' + user.VendorID).then((res) => {
                setVendor(res.data);
            }).catch(handleApiError)
        }
        // @ts-ignore
        setAnotherBillingAddress(user.LastBillingAddress);
        // @ts-ignore
        setAnotherShippingAddress(user.LastShippingAddress);

        setValue('Name', user.LastBillingAddress?.AddressPerson);
        setValue('Ort', user.LastBillingAddress?.City ?? '');
        setValue('Straße', user.LastBillingAddress?.Street ?? '');
        setValue('Company', user.LastBillingAddress?.Company ?? '');
        setValue('CompanyShipping', user.LastBillingAddress?.CompanyShipping ?? '');
        setValue('Hausnummer', user.LastBillingAddress?.Number ?? '');
        setValue('Land', user.LastBillingAddress?.Country ?? '');
        if (user.LastBillingAddress?.PostalCode) {
            setPLZ(user.LastBillingAddress?.PostalCode);
        }
        setValue('PLZ', plz);
        setValue('NameShipping', user.LastShippingAddress?.AddressPerson ?? '');
        setValue('OrtShipping', user.LastShippingAddress?.City ?? '');
        setValue('StraßeShipping', user.LastShippingAddress?.Street ?? '');
        setValue('HausnummerShipping', user.LastShippingAddress?.Number ?? '');
        setValue('LandShipping', user.LastShippingAddress?.Country ?? '');
        if (user.LastShippingAddress?.PostalCode) {
            setPLZShipping(user.LastShippingAddress?.PostalCode);
        }
        setValue('PLZShipping', plzShipping);
        if (user.Addresses?.length) {
            setAddresses(user.Addresses);
        } else {
            setAddresses([]);
        }
    }, [user])

    function commitCheckout(data) {
        /*@ts-ignore
        window.dataLayer.push({
            'event': 'fs.orderBtnClicked',
            'transaction_id': '',
            'order_number': res.OrderNumber,
            'order_amount': basketValue != '' ? basketValue : basket.TotalNice,
            'region': billingData.City ?? '',
            'country': billingData.Country ?? '',
            'plz': plz ?? 0,
            'customer_email': member.Email ?? ''
        })*/
        handleNewsletterChange(newsletterStatus);

        setLoadingCheckout(true);
        let billingData = {
            Person: data.Name,
            Number: data.Hausnummer,
            Street: data.Straße,
            ZIPCode: plz,
            City: data.Ort,
            Country: data.Land,
            UID: data.UID,
            Company: data.Company ?? '',
            IsNew: billingAddress == -1
        }

        let shippingData = {
            Person: data.NameShipping,
            Number: data.HausnummerShipping,
            Street: data.StraßeShipping,
            ZIPCode: plzShipping,
            City: data.OrtShipping,
            Country: data.LandShipping,
            UID: data.UIDShipping,
            Company: data.CompanyShipping ?? '',
            IsNew: shippingAddress == -1
        }

        let greetingCards =
            {
                GreetingsHeading: greetingHeadings,
                GreetingsName: greetingNames,
                GreetingsText: greetingTexts
            }

        Client.Member.current(auth.Key).then((res) => {
            console.log("Member")
            Client.Auth.authPostNoID(auth.Key, 'Order/submitCheckout', {
                BasketID: basket.BasketID,
                MemberID: res.data.ID,
                Remarks: remark,
                GreetingCards: greetingCards,
                BillingAddress: billingData,
                ShippingAddress: shippingData,
                NewsletterRegistration: newsletterStatus,
                DifferentShipping: hasDifferentShipping
            }).then((res) => {
                // @ts-ignore
                if (cookie.PerformanceCookies) {
                    try {
                        // @ts-ignore
                        gtag('event', 'conversion', {
                            'send_to': 'AW-10887418845/E5s1CITuqLUDEN2nw8co',
                            'transaction_id': res.data.OrderID,
                            'order_number': res.data.OrderNumber ?? 'NOORDERNUMBER',
                            'order_amount': basketValue != '' ? basketValue : basket.TotalVal,
                            'region': billingData.City ?? 'NOCITY',
                            'country': billingData.Country ?? 'NOCOUNTRY',
                            'plz': plz ?? 'NOPLZ',
                            'customer_email': res.data.MemberEmail ?? 'NOEMAIL'
                        });


                        let dataLayerPush = {
                            'cicData': {
                                'cic_transaction_id': res.data.OrderID,
                                'cic_order_number': res.data.OrderNumber ?? 'NOORDERNUMBER',
                                'cic_order_amount': res.data.OrderValue,
                                'cic_region': billingData.City ?? 'NOCITY',
                                'cic_country': billingData.Country ?? 'NOCOUNTRY',
                                'cic_plz': plz ?? 'NOPLZ',
                                'cic_customer_email': res.data.MemberEmail ?? 'NOEMAIL',
                                'cic_products': res.data.Products
                            },
                            'event': 'fs.orderBtnClicked'
                        };


                        //@ts-ignore
                        window.dataLayer.push(dataLayerPush);


                    } catch (e) {
                        console.log(e)
                    }
                } else {
                }
                window.location = res.data.URL;
                setLoadingCheckout(false);
                //@ts-ignore
                console.log(window.dataLayer)

            }).catch((error) => {
                console.log(error)

                setLoadingCheckout(false)
                handleApiError(error);
                setCheckoutFinished(false);
            });
        }).catch((error) => {
            setLoadingCheckout(false)
            handleApiError(error);
            setCheckoutFinished(false);
        });
    }

    useEffect(() => {
        if (member.MemberID) {
            Client.Basket.fetch(basket.BasketID).then((res) => {
                setFullBasket(res.data);
            }).catch(handleApiError);
        }
    }, [member.MemberID, basket.BasketAmount])

    useEffect(() => {
        let vendorList = '?filter[ID:not]=-1';
        // @ts-ignore
        if (Array.isArray(fullBasket.List)) {
            // @ts-ignore
            fullBasket.List.map((item) => {
                vendorList += '&filter[ID][]=' + item.ID;
            })
            // @ts-ignore
            if (fullBasket.List.length > 0) {
                Client.Raw.get('Vendor/getActiveVendorsMinimalized' + vendorList).then((res) => {
                    setVendors(res.data);
                    setVendorLoading(false);
                    setTimeout(() => {
                        setLoading(false)
                    }, 300)
                }).catch(handleApiError);
            }
        }
    }, [fullBasket, member.MemberID])


    // useEffect(() => {
    //     if (hasDifferentShipping && parseInt(plzShipping) == 0 || hasDifferentShipping && isNaN(parseInt(plzShipping)) || hasDifferentShipping && plzShipping == '' || plzShipping.toString().trim().length !== 4) {
    //         if(hasDifferentShipping) {
    //             setPLZErrorShipping('Bitte gib eine gültige Postleitzahl an.')
    //         }
    //     } else {
    //         setPLZErrorShipping('')
    //     }
    //
    //     console.log(plzShipping)
    // }, [hasDifferentShipping, plzShipping])
    //
    // useEffect(() => {
    //     if (parseInt(plz) == 0 || isNaN(parseInt(plz)) || plz.toString().trim().length !== 4 || hasDifferentShipping && plz == '') {
    //         setPLZError('Bitte gib eine gültige Postleitzahl an.')
    //     } else {
    //         setPLZError('')
    //     }
    //
    // }, [plz])


    useEffect(() => {
        if(!hasDifferentShipping){
            setPLZErrorShipping('');
        }
    }, [hasDifferentShipping])

    function validatePLZ(e, type) {
        let val = e.target.value;

        if (type === 'shipping') {
            setPLZShipping(val)
        } else {
            setPLZ(val)
        }

        if (val.length === 4) {
            if (type === 'shipping') {
                if (hasDifferentShipping) {
                    setPLZErrorShipping('')
                }
            } else {
                setPLZError('')
            }
        } else {
            if (type === 'shipping') {
                setPLZErrorShipping('Bitte gib eine gültige Postleitzahl an.')
            } else {
                setPLZError('Bitte gib eine gültige Postleitzahl an.')
            }
        }
    }

    function findGetParameter(parameterName) {
        var result = null,
            tmp = [];
        location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }


    useEffect(() => {
        if (findGetParameter('checkoutClear') && findGetParameter('checkoutClear') == 1) {
            Client.Auth.authPostNoID(auth.Key, 'Order/clearBasket', {
                BasketID: basket.BasketID,
            }).then(() => {
                navigate('/');
                setBasketAmount(0)
                toast.success('Deine Bestellung wurde abgebrochen.')
                toast.success('Dein Warenkorb wurde geleert.')
            })
        }
    }, [])

    if (errors.Name?.message.startsWith('Name must be a')) {
        errors.Name.message = "Bitte gib deinen Vor- und Nachnamen an.";
    }


    return (
        <>
            <Layout>
                {checkoutFinished ?
                    <FullscreenLoading/> :
                    null
                }
                <>
                    {!loading ?
                        <>
                            <Container>
                                <Row>
                                    <Col lg={12} className={"py-4"}>
                                        <div className={style.form}>
                                            Hey! Bestellungen <b>außerhalb Österreichs</b> müssen aktuell noch per E-Mail aufgegeben werden. Schick uns bitte einfach deine Bestellung, deine Adresse und deinen vollständigen Namen an <a href={"mailto:info@fairstärkt.at"}>info@fairstärkt.at</a> und wir kümmern uns um alles – wir freuen uns auf deine Nachricht und danken dir für dein Verständnis!
                                        </div>
                                    </Col>
                                </Row>
                                {!member.MemberID ?
                                    <div className={"mb-3"}>
                                        {authMode === 'register' && <>
                                            <RegisterBeforeCheckout completed={setCompletedRegister}/>
                                            <small className="d-flex gap-2">
                                                <p>Bereits registriert?</p>
                                                <a href="#" onClick={() => setAuthMode('login')}>Zum Login</a>
                                            </small>
                                        </>}
                                        {authMode === 'login' && <>
                                            <LoginForm onSuccess={member => setUser(member)}/>
                                            <small className="d-flex gap-2">
                                                <p>Noch kein Account?</p>
                                                <a href="#" onClick={() => setAuthMode('register')}>Zur
                                                    Registrierung</a>
                                            </small>
                                        </>}
                                    </div>
                                    :
                                    null
                                }

                            </Container>
                            <Form onSubmit={handleSubmit(commitCheckout)} className={"mb-5"}>
                                <Container>
                                    <Row>
                                        <Col lg={7}>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className={style.form}>
                                                        <h2 className={style.subHeading}>
                                                            <span> Rechnungsadresse </span>
                                                        </h2>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <Form.Group controlId="formAddressSelection"
                                                                            className={"mb-2"}>
                                                                    <Form.Label className={style.inputLabel}>Adresse
                                                                        auswählen</Form.Label>
                                                                    <Form.Control as={"select"}
                                                                                  className={style.inputField}
                                                                                  disabled={addresses.length === 0}
                                                                                  defaultValue={billingAddress ? billingAddress : null}
                                                                                  onChange={(event) => {
                                                                                      setBillingAddress(event.target.value)
                                                                                      setBillingAddressToUse(event.target.value)
                                                                                  }}>
                                                                        {addresses.length ?
                                                                            <>
                                                                                <option disabled={true}
                                                                                        selected={billingAddress ? false : true}>Adresse
                                                                                    auswählen
                                                                                </option>
                                                                                {addresses.map((address, index) => {
                                                                                    return (<option key={index}
                                                                                                    value={address.ID}> {address.PostalCode} {address.City}, {address.Street} {address.Number}</option>)
                                                                                })}
                                                                                <option value={-1}>Neue Adresse
                                                                                    hinzufügen
                                                                                </option>
                                                                            </> : <Loading type={"border"}
                                                                                           message={"Adressen werden geladen"}/>
                                                                        }
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Form.Group controlId="formFirstName" className="mb-2">
                                                                    <Form.Label
                                                                        className={style.inputLabel}>Vor- und
                                                                        Nachname*</Form.Label>
                                                                    <Form.Control type="text"
                                                                                  className={style.inputField} {...register('Name')}
                                                                        // @ts-ignore
                                                                                  defaultValue={anotherBillingAddress?.AddressPerson ? anotherBillingAddress.AddressPerson : user.VendorID ? vendor.CompanyName : user.Name}/>
                                                                    <Form.Text className={mainStyle.errorMsg}>
                                                                        {errors.Name?.message}
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Form.Group controlId="formCompany">
                                                                    <Form.Label
                                                                        className={style.inputLabel}>Unternehmensname</Form.Label>
                                                                    <Form.Control type="text"
                                                                                  className={style.inputField}  {...register('Company')}
                                                                                  defaultValue={user.Company}/>
                                                                    <Form.Text className={mainStyle.errorMsg}>
                                                                        {errors.Company?.message}
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Form.Group controlId="formPasswordRetype">
                                                                    <Form.Label
                                                                        className={style.inputLabel}>Straße*</Form.Label>
                                                                    <Form.Control type="text"
                                                                                  className={style.inputField} {...register('Straße')}
                                                                        // @ts-ignore
                                                                                  defaultValue={anotherBillingAddress?.Street}/>
                                                                    <Form.Text className={mainStyle.errorMsg}>
                                                                        {errors.Straße?.message}
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Form.Group controlId="formPasswordRetype">
                                                                    <Form.Label
                                                                        className={style.inputLabel}>Hausnummer*</Form.Label>
                                                                    <Form.Control type="text"
                                                                                  className={style.inputField} {...register('Hausnummer')}
                                                                        // @ts-ignore

                                                                                  defaultValue={anotherBillingAddress?.Number}/>
                                                                    <Form.Text className={mainStyle.errorMsg}>
                                                                        {errors.Hausnummer?.message}
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Form.Group controlId="formEmail">
                                                                    <Form.Label
                                                                        className={style.inputLabel}>PLZ*</Form.Label>
                                                                    <Form.Control type="text"
                                                                                  className={style.inputField}
                                                                                  onChange={(e) => validatePLZ(e, 'foo')}
                                                                        // @ts-ignore
                                                                                  value={plz}/>
                                                                    <Form.Text className={mainStyle.errorMsg}>
                                                                        {plzError}
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Form.Group controlId="formPassword">
                                                                    <Form.Label
                                                                        className={style.inputLabel}>Ort*</Form.Label>
                                                                    <Form.Control type="text"
                                                                                  className={style.inputField} {...register('Ort')}
                                                                        // @ts-ignore
                                                                                  defaultValue={anotherBillingAddress?.City}/>
                                                                    <Form.Text className={mainStyle.errorMsg}>
                                                                        {errors.Ort?.message}
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={6}>
                                                                {/*// @ts-ignore*/}
                                                                {user.VendorID ?
                                                                    <Form.Group controlId="formEmail">
                                                                        <Form.Label
                                                                            className={style.inputLabel}>UID</Form.Label>
                                                                        <Form.Control type="text"
                                                                                      className={style.inputField}  {...register('UID')}
                                                                            // @ts-ignore
                                                                                      defaultValue={vendor.UID}/>
                                                                        <Form.Text className={mainStyle.errorMsg}>
                                                                            {errors.UID?.message}
                                                                        </Form.Text>
                                                                    </Form.Group> : null
                                                                }
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Form.Group controlId="formLastName">
                                                                    <Form.Label
                                                                        className={style.inputLabel}>Land*</Form.Label>
                                                                    <div className={style.dropDownChevron}>
                                                                        <Form.Control as="select"
                                                                                      className={style.dropDown}
                                                                                      {...register('Land')}
                                                                            // @ts-ignore

                                                                                      defaultValue={anotherBillingAddress?.Country}>
                                                                            <option value={"AT"}> Österreich</option>
                                                                        </Form.Control>
                                                                        <Form.Text className="errorMsg">
                                                                            {errors.Land?.message}
                                                                        </Form.Text>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col
                                                                lg={6}
                                                                className={"pt-4"}
                                                            >
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    className={style.checkbox}
                                                                    {...register('DifferentCheck')}
                                                                    onClick={() => setHasDifferentShipping(!hasDifferentShipping)}
                                                                    defaultChecked={hasDifferentShipping}
                                                                    label="Abweichende Lieferadresse?"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                {hasDifferentShipping &&
                                                    <Col lg={12} className={"mt-3"}>
                                                        <div className={style.form}>
                                                            <h2 className={style.subHeading}>
                                                                <span> Lieferadresse </span>
                                                            </h2>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <Form.Group controlId="formAddressSelection1"
                                                                                className={"mb-2"}>
                                                                        <Form.Label className={style.inputLabel}>
                                                                            Adresse auswählen
                                                                        </Form.Label>
                                                                        <Form.Control as={"select"}
                                                                                      className={style.inputField}
                                                                                      disabled={addresses.length === 0}
                                                                                      defaultValue={shippingAddress ? shippingAddress : null}
                                                                                      onChange={(event) => {
                                                                                          setShippingAddress(event.target.value)
                                                                                          setShippingAddressToUse(event.target.value)
                                                                                      }}>
                                                                            {addresses.length ?
                                                                                <>
                                                                                    <option disabled={true}
                                                                                            selected={shippingAddress ? false : true}>Adresse
                                                                                        auswählen
                                                                                    </option>
                                                                                    {addresses.map((address, index) => {
                                                                                        return (<option key={index}
                                                                                                        value={address.ID}> {address.PostalCode} {address.City}, {address.Street} {address.Number}</option>)
                                                                                    })}
                                                                                    <option value={-1}>Neue Adresse
                                                                                        hinzufügen
                                                                                    </option>
                                                                                </> : <Loading type={"border"}
                                                                                               message={"Adressen werden geladen"}/>
                                                                            }
                                                                        </Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Form.Group controlId="formFirstName1"
                                                                                className="mb-2">
                                                                        <Form.Label
                                                                            className={style.inputLabel}>Vor- und
                                                                            Nachname*</Form.Label>
                                                                        <Form.Control type="text"
                                                                                      className={style.inputField}{...register('NameShipping')}
                                                                            // @ts-ignore
                                                                                      defaultValue={anotherShippingAddress?.AddressPerson ? anotherShippingAddress.AddressPerson : user.VendorID ? vendor.CompanyShipping : user.Name}/>
                                                                        <Form.Text className={mainStyle.errorMsg}>
                                                                            {errors.NameShipping?.message}
                                                                        </Form.Text>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Form.Group controlId="formCompanyShipping">
                                                                        <Form.Label
                                                                            className={style.inputLabel}>Unternehmensname</Form.Label>
                                                                        <Form.Control type="text"
                                                                                      className={style.inputField} {...register('CompanyShipping')}
                                                                            // @ts-ignore
                                                                                      defaultValue={user.CompanyShipping}/>
                                                                        <Form.Text className={mainStyle.errorMsg}>
                                                                            {errors.CompanyShipping?.message}
                                                                        </Form.Text>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col lg={6}>
                                                                    <Form.Group controlId="formPasswordRetype1">
                                                                        <Form.Label
                                                                            className={style.inputLabel}>Straße</Form.Label>
                                                                        <Form.Control type="text"
                                                                                      className={style.inputField} {...register('StraßeShipping')}
                                                                            // @ts-ignore

                                                                                      defaultValue={anotherShippingAddress?.Street}/>
                                                                        <Form.Text className={mainStyle.errorMsg}>
                                                                            {errors.StraßeShipping?.message}
                                                                        </Form.Text>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Form.Group controlId="formPasswordRetype1">
                                                                        <Form.Label
                                                                            className={style.inputLabel}>Hausnummer</Form.Label>
                                                                        <Form.Control type="text"
                                                                                      className={style.inputField} {...register('HausnummerShipping')}
                                                                            // @ts-ignore

                                                                                      defaultValue={anotherShippingAddress?.Number}/>
                                                                        <Form.Text className={mainStyle.errorMsg}>
                                                                            {errors.HausnummerShipping?.message}
                                                                        </Form.Text>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Form.Group controlId="formEmail1">
                                                                        <Form.Label
                                                                            className={style.inputLabel}>PLZ</Form.Label>
                                                                        <Form.Control type="text"
                                                                                      className={style.inputField}
                                                                                      onChange={(e) => validatePLZ(e, 'shipping')}
                                                                            // @ts-ignore
                                                                                      value={plzShipping}/>
                                                                        <Form.Text className={mainStyle.errorMsg}>
                                                                            {plzErrorShipping}
                                                                        </Form.Text>

                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Form.Group controlId="formPassword1">
                                                                        <Form.Label
                                                                            className={style.inputLabel}>Ort</Form.Label>
                                                                        <Form.Control type="text"
                                                                                      className={style.inputField} {...register('OrtShipping')}
                                                                            // @ts-ignore
                                                                                      defaultValue={anotherShippingAddress?.City}/>
                                                                        <Form.Text className={mainStyle.errorMsg}>
                                                                            {errors.OrtShipping?.message}
                                                                        </Form.Text>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    {/*// @ts-ignore*/}
                                                                    {user.VendorID ?
                                                                        <Form.Group controlId="formEmail1">
                                                                            <Form.Label
                                                                                className={style.inputLabel}>UID</Form.Label>
                                                                            <Form.Control type="text"
                                                                                          className={style.inputField}  {...register('UIDShipping')}
                                                                                // @ts-ignore
                                                                                          defaultValue={vendor.UID}/>
                                                                            <Form.Text className={mainStyle.errorMsg}>
                                                                                {errors.UID?.message}
                                                                            </Form.Text>
                                                                        </Form.Group> : null
                                                                    }
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Form.Group controlId="formLastName1">
                                                                        <Form.Label
                                                                            className={style.inputLabel}>Land</Form.Label>
                                                                        <div className={style.dropDownChevron}>
                                                                            <Form.Control as="select"
                                                                                          className={style.dropDown}
                                                                                          {...register('LandShipping')}
                                                                                // @ts-ignore

                                                                                          defaultValue={anotherShippingAddress?.Country}>
                                                                                <option value={"AT"}> Österreich
                                                                                </option>
                                                                            </Form.Control>
                                                                            <Form.Text className="errorMsg">
                                                                                {errors.LandShipping?.message}
                                                                            </Form.Text>
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                }
                                                <Col lg={12} className={"mt-3"}>
                                                    <div className={style.anmerkungen}>
                                                        <Form.Group>
                                                            <Form.Label
                                                                className={style.inputLabel}>Anmerkungen für den
                                                                Händler</Form.Label>
                                                            <Form.Control as="textarea" rows={6}
                                                                          onChange={(event) => setRemark(event.target.value)}
                                                                          className={style.textArea}/>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={5}>
                                            <CheckoutBasket setBasketEmpty={setBasketEmpty}
                                                            setGreetingHeading={setGreetingHeadings}
                                                            setGreetingName={setGreetingNames}
                                                            setGreetingText={setGreetingTexts}
                                                            vendors={vendors}
                                                            setBasketValue={setBasketValue}
                                            >
                                                {vendors && !vendorLoading ? <Col lg={12} className={'mt-3'}>
                                                    <Form.Group className={"d-flex"}>
                                                        <Form.Check id={'AcceptVendorAGB'}
                                                                    className={style.checkboxAGB} {...register('AcceptVendorAGB')}
                                                                    onChange={() => {
                                                                        const checked = !vendorCheckedAGB;
                                                                        setVendorCheckedAGB(checked)
                                                                        setCheckedAGB(checked);
                                                                        setValue('AcceptVendorAGB', checked);
                                                                        setValue('AcceptAGB', checked);
                                                                    }}
                                                        />
                                                        <Form.Label
                                                            htmlFor={'AcceptVendorAGB'}
                                                            className={style.inputLabel}>
                                                            {vendors.length > 1 ?
                                                                <span>
                                                            Ich hab die AGBs von fairStärkt (<Link target={'_blank'}
                                                                                                   to={'/agb'}>AGB</Link>)
                                                            und folgender Händler gelesen und bin mit diesen einverstanden:
                                                        </span>
                                                                :
                                                                <span>
                                                            Ich habe die AGBs von fairStärkt (<Link target={'_blank'}
                                                                                                    to={'/agb'}>AGB</Link>)
                                                            und vom Händler gelesen und bin mit diesen einverstanden:
                                                        </span>
                                                            }


                                                            {vendors.map((item) => {
                                                                return (
                                                                    <>
                                                                        <br/>
                                                                        <a href={'/haendleruebersicht/' + item.URLSegment + '?tab=agb'}
                                                                           target={"_blank"}> {item.Title} </a>
                                                                    </>
                                                                )
                                                            })}
                                                        </Form.Label>
                                                        <Form.Text className="errorMsg">
                                                            {errors.AcceptVendorAGB?.message}
                                                            {errors.AcceptAGB?.message}
                                                        </Form.Text>
                                                    </Form.Group>
                                                    {!user.NewsletterStatus ?
                                                        <Form.Group controlId="formNewsletter">
                                                            <Form.Label
                                                                className={style.inputLabel}>{newsletterText}</Form.Label>
                                                            <Form.Check
                                                                type="checkbox"
                                                                onChange={(e) => setNewsletterStatus(e.target.checked)}
                                                                id="custom-switch"
                                                                checked={newsletterStatus}
                                                                label={'Zum Newsletter anmelden'}
                                                            />
                                                            <Form.Text className={mainStyle.errorMsg}>

                                                            </Form.Text>
                                                        </Form.Group> : null}
                                                </Col> : null}
                                                <div
                                                    className={"d-flex flex-column align-items-center justify-content-center"}>
                                                    <Button type={"submit"}
                                                            className={loadingCheckout ? style.pulsingColor + ' ' + style.submitButton : style.submitButton}
                                                            disabled={basketEmpty || checkoutFinished || loadingCheckout || vendorLoading || !member.MemberID || !checkedAGB || !vendorCheckedAGB || plzError !== '' || plzErrorShipping !== ''}>
                                                        {loadingCheckout ? 'Zahlung wird verarbeitet...' : 'Jetzt zahlungspflichtig bestellen'}
                                                    </Button>
                                                    <small className={"text-center mt-2"}>Deine Zahlungsart kannst du im
                                                        nächsten Schritt auswählen</small>
                                                </div>
                                            </CheckoutBasket>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>

                        </>
                        : <Loading type={"border"} message={"Daten werden geladen."}/>
                    }
                </>

            </Layout>
        </>

    )
}

export default connector(CheckoutPage);
